'use strict';

import { ajax } from '../../_scripts/commons/ajax'
import Snackbar from 'node-snackbar';

export default class From {
  constructor() {
    $('body').on('submit', 'form:not([data-ajax-form]):not([data-submitted]):not([data-fm-namespace])', function(e) {
      e.preventDefault();

      $(this).data('submitted', true);

      $(this).attr('data-submitted', true);

      $(this).find('[name]').each(function() {
        if (!$(this).val()) {
          $(this).attr('data-name', $(this).attr('name'));

          $(this).attr('name', null);
        }
      });

      const cacheid = window.location.hash.substring(1)

      if (cacheid) {
        $(this).append('<input type="hidden" name="_cacheid" value="' + cacheid + '"/>')
      }

      $(this).submit();
    });

    $('body').on('submit', '[data-ajax-form]', function(e) {
      e.preventDefault();

      ajax.run({
        url: $(this).attr('action'),
        type: $(this).attr('method') || 'get',
        data: $(this).closest('form').serialize()
      }).then(json => {
        Snackbar.show({
          text: json.message,
          pos: 'bottom-center',
          showAction: false
        });
      });

      $(this).closest('.modal').modal('toggle');
    });

    $('body').on('click', '[data-remove-selected]', function(e) {
      const $form = $(this).closest('form')

      $form.find('input[type="checkbox"]').each(function() {
        if( $(this).prop('checked') === true ){
          $(this).trigger('click');
        }
      });

      $('[data-selected-results]').val('[]');
    });

    $(document).on('handlebars.render.end', function(){
      if ( !$('[data-selected-results]').length ) return;

      const selected = JSON.parse( $('[data-selected-results]').val() );

      const found = !(!selected.length);

      const $target = $('.detail-button');

      $target[(found ? 'remove' : 'add') + 'Class']('disabled');

      if (found) {
        $target.removeAttr('disabled');
      } else {
        $target.attr('disabled');
      }

      selected.forEach(index => {
        $('input[type="checkbox"][index="' + index + '"]').prop('checked', true);
      });
    })

    $('body').on('change', '[data-checkbox-lista-opac]', function(e) {
      let $input = $(this)

      let index = $input.attr('index')

      let val = JSON.parse( $('[data-selected-results]').val() )

      if( $input.is(':checked') ){
        val.push(index)

        $('[data-selected-results]').val( JSON.stringify(val) )
      } else {
        val = val.filter(value => value !== index)

        $('[data-selected-results]').val( JSON.stringify(val) )
      }

      const found = !(!JSON.parse( $('[data-selected-results]').val() ).length);

      const $target = $($(this).data('target'));

      $target[(found ? 'remove' : 'add') + 'Class']('disabled');

      if (found) {
        return $target.removeAttr('disabled');
      }

      return $target.attr('disabled');
    });

    $('body').on('submit', '.statistics-form', function(e) {
      e.preventDefault()
    });

    $('body').on('click', '.statistics-form .arrow', function(e) {
      e.preventDefault()

      const isLeft = $(this).hasClass('left')

      const $month = $(this).parent().find('.month')

      const $year = $(this).parent().find('.year')

      const currentMonth = parseInt($month.val())

      let newMonth, newYear

      if (isLeft) {
        newMonth = currentMonth === 1 ? 12 : currentMonth - 1

        newYear = $month.val() === '1' ? $year.val() - 1 : $year.val()
      } else {
        newMonth = currentMonth === 12 ? 1 : currentMonth + 1

        newYear = $month.val() === '12' ? parseInt($year.val()) + 1 : $year.val()
      }

      $month.val(newMonth)

      $year.val(newYear).trigger('change')
    });

    $('body').on('change', '.statistics-form select', function() {
      const $month = $(this).closest('.statistics-form').find('.month')

      const $year = $(this).closest('.statistics-form').find('.year')

      const { year: minYear, month: minMonth } = $('[data-min-date]').data('min-date')

      const isMinYear = $year.val() === minYear

      const isMinMonth = $month.val() === minMonth

      const isCurrentYear = parseInt($year.val()) === new Date().getFullYear()

      const isCurrentMonth = parseInt($month.val()) === new Date().getMonth() + 1

      $(this).parent().siblings('.arrow.left')[(isMinYear && isMinMonth ? 'add' : 'remove') + 'Class']('disabled')

      $(this).parent().siblings('.arrow.right')[(isCurrentYear && isCurrentMonth ? 'add' : 'remove') + 'Class']('disabled')

      if ($(this).hasClass('year')) {
        $month.find('option').removeAttr('disabled')

        if (isMinYear) {
          $month.find('option').each(function () {
            if (parseInt($(this).attr('value')) < minMonth) {
              $(this).attr('disabled', true)
            }
          })
        }

        if (isCurrentYear) {
          $month.find('option').each(function () {
            if (parseInt($(this).attr('value')) > new Date().getMonth() + 1) {
              $(this).attr('disabled', true)
            }
          })
        }
      } else {
        $year.find('option').removeAttr('disabled')

        if (parseInt($month.val()) < parseInt(minMonth)) {
          $year.find('option:last').attr('disabled', true)
        }

        if (parseInt($month.val()) > new Date().getMonth() + 1) {
          $year.find('option:first').attr('disabled', true)
        }
      }

      $(this).closest('form').trigger('submit')
    });
  }
}
