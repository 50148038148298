// Main javascript entry point
// Should handle bootstrapping/starting application

'use strict';

import '@fortawesome/fontawesome-free/js/all.js';
import 'lightbox2';

import Header from '../_modules/header/header';
import SearchBlock from '../_modules/search-block/search-block';
import JQueryHandlebars from './_handlebars';
import Ajax from './_ajax';
import Popover from './_popover';
import InfiniteScroll from '../_modules/infinite-scroll/infinite-scroll';
import Commons from './commons';
import Autocomplete from '../_modules/autocomplete/autocomplete';
import Bookmark from '../_modules/bookmark/bookmark';
import Modal from '../_modules/modal/modal';
import Clipboard from '../_modules/clipboard/clipboard';
import Form from '../_modules/form/form';
import Table from '../_modules/table/table';
import Card from '../_modules/card/card';
import MusicSheet from '../_modules/music-sheet/music-sheet';
import Dropdown from './_dropdown';
import VirtualKeyboard from '../_modules/virtual-keyboard/virtual-keyboard';
import Sticky from '../_modules/sticy/sticky';
import GotoTop from '../_modules/goto-top/goto-top';
import Highlight from '../_modules/highlight/highlight';
import TabIndex from '../_modules/tab-index/tab-index';
import RangePicker from '../_modules/range-picker/range-picker';
import Chart from '../_modules/chart/chart';
import Periodical from '../_modules/periodical/periodical';
import NavigationCache from '../_modules/navigation-cache/navigation-cache';
import MapBlock from '../_modules/map-block/map-block';
import SliderBlock from '../_modules/slider/slider';

$(() => {
  new Header();
  new JQueryHandlebars();
  new SearchBlock();
  new Commons();
  new Autocomplete();
  new Popover();
  new Bookmark();
  new Modal();
  new Ajax();
  new Clipboard();
  new Dropdown();
  new Form();
  new Table();
  new MusicSheet();
  new Card();
  new VirtualKeyboard();
  new Sticky();
  new GotoTop();
  new TabIndex();
  new RangePicker();
  new Chart();
  new Periodical();
  new Highlight();
  new NavigationCache();
  new MapBlock();
  new InfiniteScroll();
  new SliderBlock();

  // Commons
  $('[data-toggle="tooltip"]').tooltip();
});
