'use strict';

export default class Chart {
  constructor() {
    (function($) {
      $.extend({
        chart: {
          init: $obj => {
            const chartConfig = $obj.data('chart');

            Highcharts.chart($obj.get(0), {
              credits: {
                enabled: false,
              },
              chart: {
                type: 'column',
                animation: false,
              },
              plotOptions: {
                column: {
                  borderWidth: 0,
                }
              },
              title: {
                text: chartConfig.title || ''
              },
              xAxis: {
                type: 'category',
                labels: {
                  rotation: -45,
                  style: {
                    fontSize: '6px',
                    fontFamily: 'Verdana, sans-serif'
                  }
                }
              },
              yAxis: {
                min: 0,
                max: null,
                title: {
                  enabled: false
                },
                labels: {
                  style: {
                    fontSize: '6px',
                    fontFamily: 'Verdana, sans-serif'
                  }
                }
              },
              legend: {
                verticalAlign: 'top',
                itemStyle: {
                  fontSize: '6px',
                  fontFamily: 'Verdana, sans-serif'
                }
              },
              responsive: {
                rules: [
                  {
                    chartOptions: {
                      yAxis: {
                        labels: {
                          style: {
                            fontSize: '8px',
                          }
                        }
                      },
                      xAxis: {
                        labels: {
                          style: {
                            fontSize: '8px',
                          }
                        }
                      },
                      legend: {
                        itemStyle: {
                          fontSize: '8px'
                        }
                      }
                    },
                    condition: {
                      minWidth: 195
                    }
                  },
                  {
                    chartOptions: {
                      yAxis: {
                        labels: {
                          style: {
                            fontSize: '10px',
                          }
                        }
                      },
                      xAxis: {
                        labels: {
                          style: {
                            fontSize: '10px',
                          }
                        }
                      },
                      legend: {
                        itemStyle: {
                          fontSize: '10px'
                        }
                      }
                    },
                    condition: {
                      minWidth: 300
                    }
                  }
                ]
              },
              series: [{
                animation: false,
                name: chartConfig.legend,
                data: chartConfig.data
              }]
            });
          }
        },
        ajaxChart: {
          init: ($obj) => {
            const config = $obj.data('ajax-chart');

            if (!config) {
              return;
            }

            if (config.url) {
              $.ajax({
                url: config.url,
                method: 'get',
                success: (res) => {
                  $.ajaxChart.render($obj, res);
                }
              })
            } else {
              $.ajaxChart.render($obj, config);
            }
          },
          render: ($obj, res) => {
            if (typeof (res.chart) == 'object' && res.chart.type == 'flat') {
              return $.ajaxChart.renderFlatChart($obj, res);
            }

            Highcharts.chart($obj.get(0), res);
          },
          renderFlatChart: ($obj, res) => {

            let $chartFlat = $('<div class="chart-flat"></div>');

            //prepare series html
            res.series.forEach(series => {
              let $series = $(`
                <div class="chart-series">
                  <div class="title">${series.name || ''}</div>
                  <div class="series-content"></div>
                </div>`
              );

              //prepare data html
              series.data.forEach(data => {
                let hasIcon = typeof (data.icon) == 'object';

                let $data = $(
                  `<div class="chart-data">
                    <div class="content">
                      <div class="data-icon ${hasIcon ? '' : 'd-none'}">
                        <span class="material-icons">${data.icon.content}</span>
                      </div>
                      <div class="data-info">
                        <div class="title">${data.name}</div>
                        <div class="value">${Math.round(data.y * 100) / 100}</div>
                      </div>
                    </div>
                  </div>`
                );

                $series.children('.series-content').append($data);
              })

              $chartFlat.append($series);
            });

            $obj.append($chartFlat);
          }
        }
      });
    })($);

    const initChart = () => {
      $('[data-chart]:not([data-has-chart])').each(function() {
        $(this).data('has-chart', true);

        $(this).attr('data-has-chart', true);

        $.chart.init($(this));
      });

      $('[data-ajax-chart]:not([data-has-chart])').each(function () {
        $(this).data('has-chart', true);

        $(this).attr('data-has-chart', true);

        $.ajaxChart.init($(this));
      });
    };

    initChart();

    $(document).on('handlebars.render.end', function() {
      initChart();
    });
  }
}
