'use strict';

export default class SliderBlock {
  constructor() {
    $('[data-slider-config]').each(function () {
      const sliderConfig = $(this).data('slider-config');

      tns({
        controlsText: ['navigate_before', 'navigate_next'],
        ...sliderConfig
      })
    });

    const positionFullWidth = () => {
      $('.full-width').each(function () {
        const offset = $(this).closest('.full-width-wrapper').offset().left;

        $(this).css({ left: '-' + offset + 'px' });

        const slidesNum = $(this).find('.tns-item').length;

        $(this).find('.tns-carousel').css({ width: 'calc(100vw * ' + slidesNum + ')' });
      })

      if (window.innerWidth < 768) {
        $('.slider-block:not(.full-width)').each(function () {
          const offset = $(this).parent().offset().left;

          const parentWidth = $(this).parent().width();

          $(this).css({ left: '-' + offset + 'px' });

          const slidesNum = $(this).find('.tns-item').length;

          if (slidesNum) {
            const width = (parentWidth * slidesNum) + 10

            $(this).find('.tns-carousel').css({ width: 'calc(' + width + 'px)' });
          }
        })
      }

    }

    $(window).on('resize', function () {
      positionFullWidth();
    })

    positionFullWidth();
  }
}
